import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { ItemsStore, ViewStore, FacetsStore } from "./Store";
import CircularProgress from "@mui/material/CircularProgress";
import { Stack } from "@mui/material";
import { createSearchParams, useSearchParams } from "react-router-dom";
// import { GetSearchResults, BuildSolrSearchQuery } from "./SearchEngine";
import { Viewer } from "../components/Viewer";

import InfiniteScroll from "react-infinite-scroll-component";

import archieExports from "../archie-exports";

export const PhotoGallery = () => {
  const { items } = ItemsStore();
  const { facets } = FacetsStore();
  const { view, setView } = ViewStore();
  const [images, setImages] = useState([]);
  const [progress, setProgress] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [params, setSearchParams] = useSearchParams();

  const fetchMoreData = () => {
    let total =
      facets.counters.dcType[facets.counters.dcType.indexOf("image") + 1];
    if (parseInt(params.get("firstRow")) + 50 >= total) {
      setHasMore(false);
    } else if (
      parseInt(params.get("firstRow")) >= 1 &&
      parseInt(params.get("firstRow")) + 50 < total
    ) {
      params.set("firstRow", parseInt(params.get("firstRow")) + 50);

      setSearchParams(createSearchParams(params));
      // let searchQuery = "";

      // searchQuery = BuildSolrSearchQuery(params);

      // GetSearchResults(searchQuery, params, false);
    }
  };

  useEffect(() => {
    if (parseInt(params.get("firstRow")) === 1) {
      setImages([]);
      setHasMore(true);
    }
    const onlyImageItems = items.filter(
      (item, i) => item.dcType === "image" && i >= images.length
    );
    const getThumbnailUrl = (item) => {
      let S3OriginalsUrl = "";
      switch (item.dcAccessRights) {
        case "public":
          S3OriginalsUrl =
            archieExports["public_assets_bucket"] +
            "/thumbnails/" +
            item.id +
            ".webp";
          break;
        case "private":
          S3OriginalsUrl =
            archieExports["private_assets_bucket"] +
            "/thumbnails/" +
            item.id +
            ".webp";
          break;

        default:
          S3OriginalsUrl =
            "https://archie-beeri-" +
            item.dcAccessRights +
            ".s3-eu-west-1.amazonaws.com/originals/" +
            item.id +
            ".png";
      }
      return S3OriginalsUrl;
    };
    const loadImage = (item) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        setImages((prev) => [...prev, { data: item, img: loadImg }]);
        loadImg.src = getThumbnailUrl(item);

        // wait 2 seconds to simulate loading time
        loadImg.onload = () => {
          setProgress((prev) => prev + 1);
          resolve(getThumbnailUrl(item));
        };

        loadImg.onerror = (err) => reject(err);
      });
    };

    Promise.all(onlyImageItems.map((item) => loadImage(item)))
      .then(() => {
        setProgress(1);
      })
      .catch((err) => console.log("Failed to load images", err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const handleClick = (item) => {
    // params.set("id", item.data.id);
    // setSearchParams(params);
    setView(item.data);
  };

  return (
    <div>
      {view ? <Viewer isPhotoGallery={true} /> : null}
      <InfiniteScroll
        next={fetchMoreData}
        dataLength={images.length}
        hasMore={hasMore && facets.total > 50}
        loader={
          <Stack style={{ width: "100%" }} alignItems="center">
            <CircularProgress
              sx={{ color: "black" }}
              variant="determinate"
              value={Math.floor((progress / 50) * 100)}
              size={75}
            ></CircularProgress>
          </Stack>
        }
      >
        <Container>
          {images.map((item, i) => {
            return (
              <ImgWrapper
                width={item.img.width}
                height={item.img.height}
                onClick={() => {
                  handleClick(item);
                }}
              >
                <Filler
                  width={item.img.width}
                  height={item.img.height}
                ></Filler>
                <Img
                  alt="תמונה ממוזערת למוצג מסוג תמונה"
                  src={item.img.currentSrc}
                  key={i}
                />
              </ImgWrapper>
            );
          })}
        </Container>
      </InfiniteScroll>
    </div>
  );
};

const Container = styled.div`
  margin: 20px 20px 0 20px;
  display: flex;
  flex-wrap: wrap;
  direction: rtl;
  gap: 5px;

  :after {
    content: "";
    flex-grow: 999999999;
  }
`;

const Img = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  vertical-align: bottom;
  cursor: pointer;
`;

const ImgWrapper = styled.div`
  margin: 2px;
  background-color: violet;
  position: relative;

  width: ${(props) => (props.width * 200) / props.height + "px"};
  flex-grow: ${(props) => (props.width * 200) / props.height};
`;

const Filler = styled.i`
  display: block;
  padding-bottom: ${(props) => (props.height / props.width) * 100 + "%"};
`;
